import React from "react";
import {SC} from "./header.styled";
import RouteLink from "../../../app/components/RouteLink";
import useTranslations from "../../../../utils/hooks/useTranslations";
import LoginOrInfo from "../../../common/components/LoginOrInfo";
import useRoutes from "../../../../routing/utils/useRoutes";
import SearchForm from "../../../homepage/components/searchForm";
import getNextConfig from "next/config";
import DevBanner from "../../../common/components/devBanner";
import uuid from "react-uuid";

interface Props {
  isIndex: boolean
}

const HeaderWrapper: React.FC<Props> = ({isIndex}) => {

  const routes = useRoutes();

  const {ts} = useTranslations();
  const {phone, email, instagram, facebook} = getNextConfig().publicRuntimeConfig.parameters;

  return <SC.Header $isIndex={isIndex} className={isIndex ? "index" : undefined}>
    <DevBanner/>
    <div className={'displayFlex flexSpaceBetween header-wrapper'}>
      <RouteLink to={routes.index.url()} className={'navigation__logo'}>
        <img alt="" src={isIndex ? '/images/logoWhite.svg' : '/images/logo.png'}/>
      </RouteLink>
      <div className={'mobile-hidden displayFlex flexCenter flexColumn'}>
        <div className={'navigation'}>
          <RouteLink to={routes.articles.url()} key={uuid()}>{ts('navigation:blog')}</RouteLink>
          <RouteLink to={routes.applications.url()} key={uuid()}>{ts('navigation:apps')}</RouteLink>
          <RouteLink to={routes.forSportground.url()} key={uuid()}>{ts('navigation:forSportgrounds')}</RouteLink>
        </div>
      </div>
      <div className={'displayFlex flexCenter flexColumn'}>
        <LoginOrInfo/>
      </div>
    </div>
    {isIndex && <div className="container searchContainer">
      <div className="searchForm">
        <p>{ts('homepage:title')}</p>
        <SearchForm/>
      </div>
        <div className="contactBar flexJustifyBetween mo-flexColumn">
          <div className="contact">
            <div className={"contact-email-phone"}>
              <a href={`tel:${phone}`} className="phone">
                {phone}
              </a>
              <a href={`mailto:${email}`} className="mail">
                {email}
              </a>
            </div>
          </div>
          <div className="flexJustifyBetween social mr-4">
            <div className={"desc"}>{ts('homepage:newsFromSportybe')}</div>
            <div>
              <RouteLink to={instagram} className="instagram" target="_blank"></RouteLink>
              <RouteLink to={facebook} className="facebook" target="_blank"></RouteLink>
            </div>
          </div>
        </div>
    </div>}
  </SC.Header>
}

export default HeaderWrapper;
