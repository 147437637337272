import React, {useEffect} from 'react';
import CalendarPicker from './calendarPicker/calendarPicker';
import useTranslations from '../../../utils/hooks/useTranslations';
import {useDate} from '../../homepage/hooks/useDate';
import {getDateOfWeek} from '../../../helpers/dateHelper';

export interface ComponentProps {
  label: string,
  classNameLabel?:string,
  defaultValue?: string | null,
  selectDateTime: (date: Date) => void
  customRef?: any

}

const DateAndTimeBar: React.FC<ComponentProps> = ({label,customRef,classNameLabel='mobile-hidden', defaultValue, selectDateTime}) => {
  const {ts} = useTranslations();
  const {date, setDate, initDate} = useDate();

  const saveDateAndTime = (date: Date) => {
    setDate(date);
    selectDateTime(date);
  }

  useEffect(() => {
    if (defaultValue) {
      setDate(new Date(defaultValue));
      return;
    }
    initDate()
  }, [defaultValue])

  return <div className={'searchForm__item'}>
    <span className={classNameLabel}>{label}</span>
    <span className={'searchForm__item__date'}>
                <div className={'date-input displayFlex align-items-center'}>
                    <div className={'searchForm__item__date__day'}>{ts(getDateOfWeek(date.getDay()))}</div>
                    <div className={'searchForm__item__date__date'}>
                        {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
                    </div>
                    <div className={'searchForm__item__date__in'}>{ts('datepicker:in')}</div>
                    <div className={'searchForm__item__date__hour'}>
                        {date.getHours()}:{date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}
                    </div>
                </div>
            </span>
    <div className={'searchForm__item__invisible'}>
      <CalendarPicker date={date} setDate={saveDateAndTime} customRef={customRef}/>
    </div>
  </div>;
};

export default DateAndTimeBar;
