import {State} from 'src/types';

export const selectClosestLocation = (state: State) => {
    return state.catalog.location.location;
};

export const selectSuggestAddress = (state: State) => {
    return state.catalog.location.suggest;
};

export const selectSuggestCategory = (state: State) => {
    return state.catalog.category.suggest;
};

export const selectFavoriteCategories = (state: State) => {
    return state.catalog.category.favorite;
};

export const selectFavoriteLocations = (state: State) => {
    return state.catalog.location.favorite;
};

export const selectSearchFormData = (state: State) => {
    return state.homepage.form;
};

export const selectSearchData = (state: State) => {
    return state.catalog.sportground.search.available;
};

export const selectSearchCatalogData = (state: State) => {
  return state.catalog.sportground.search.catalog;
};

export const selectSearchInformation = (state: State) => {
    return state.catalog.sportground.search.information;
};

export const selectSearchParameters = (state: State) => {
    return state.catalog.sportground.search.parameters;
};

export const selectSearchFilter = (state: State) => {
    return state.catalog.sportground.search.filter;
};

export const selectCategorySportgrounds = (state: State) => {
    return state.catalog.sportground.detail.sportgrounds;
};

export const selectCategoryCatalog = (state: State) => {
    return state.catalog.category.catalog.categories;
};

export const selectCategoryCatalogFilter = (state: State) => {
    return state.catalog.category.catalog.filter;
};

export const selectSportgroundDetailOpenStatus = (state: State) => {
    return state.catalog.sportground.detail.openStatus;
};

export const selectSportgroundDetailOpenHourList = (state: State) => {
    return state.catalog.sportground.detail.openHourList;
};

export const getArticlesCategory = (state: State) => {
    return state.catalog.category.detail.articles;
};

export const getSportgroundReviews = (state: State) => {
    return state.catalog.sportground.detail.reviews;
};


