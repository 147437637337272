import React from "react";
import {SC} from "./footer.styled";
import FooterContacts from "../../../common/components/footer/footerContacts";
import FooterAbout from "../../../common/components/footer/footerAbout";
import TopCitiesBar from "../../../homepage/components/topCitiesBar";
import Promo from "../promo";

interface Props {
  isIndex: boolean
}

const FooterWrapper: React.FC<Props> = ({isIndex}) => {

  return <SC.Footer>
    {isIndex && <TopCitiesBar/>}
    {!isIndex && <FooterAbout/>}
    <Promo showIcon={!isIndex}/>
    <FooterContacts/>
  </SC.Footer>
}

export default FooterWrapper;
