import React, {useEffect, useState} from 'react';
import useTranslations from '../../../utils/hooks/useTranslations';
import {useDispatch, useSelector} from 'react-redux';
import {selectTopCities} from '../reducers/selectors';
import {loadTopCities} from '../actions';
import RouteLink from "../../app/components/RouteLink";
import {useRouting} from "../../../routing/utils/useRouting";
import {v4 as uuidv4} from 'uuid';

const TopCitiesBar: React.FC = () => {
    const {ts} = useTranslations();

    const [refreshKey] = useState<number>(0);
    const {routes} = useRouting();
    const dispatch = useDispatch();
    const topCities = useSelector(selectTopCities);

    useEffect(() => {
        dispatch(loadTopCities(3, 1));
    }, [refreshKey]);


    return   <div className="container footer-top-cities">
      <div className={'footer-top-cities-row'}>
        <div className={'displayFlex flexColumn flexCenter'}>
          <div>
            <h1 className={'title mobile-hidden'}>{ts('homepage:location.heading')}</h1>
            <div>
              <div className="">
                <div className="displayFlex flexRow city-block-wrapper">
                  {topCities && topCities.map((city) =>
                    <RouteLink to={routes.catalogSportgrounds.url()} className="city-block" key={uuidv4()}>
                      {city.name}
                      <img className={'citi-block-vector'} src={'icons/Vector 1.svg'}/>
                    </RouteLink>
                  )}
                </div>
                <div className="col-md-12 col-sm-12 more-info">
                  <RouteLink to={routes.cities.url()}>
                    {ts('homepage:location.viewAllTopCities')}
                  </RouteLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 className={'title mobile-show'}>{ts('homepage:location.heading')}</h1>
          <img
            src="/images/homepageLocation.jpg"
            className={'image img-fluid'}
            alt={'SportyBe logo'}
            loading={'lazy'}
          />
        </div>
      </div>
    </div>
};

export default TopCitiesBar;
