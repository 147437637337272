import {Category} from '../models';
import ApiClient from '../../../lib/api/src/api';
import {GenericResponse, Page} from '../../common/models';
import {useQuery} from 'react-query';

export const getAllCategoryQueryKey = () => 'category_all';

export const getAllCategories = async (): Promise<Category[]> => {
    // @ts-ignore
    const response = await new ApiClient().post<GenericResponse<Page<Category>>>('CATEGORY_CACHE_FINDER',{urlParams:{size:999,sort:'name,asc'},data:{}},);
    return response.result.content
};

export const useAllCategoriesQuery = () => {
    return useQuery(getAllCategoryQueryKey(), () => getAllCategories());
};
