import React from 'react';
import useTranslations from "../../../../utils/hooks/useTranslations";

const FooterAbout: React.FC = () => {
    const {ts} = useTranslations();

    return <div className="footer-about">
        <div className="container">
            <div className="displayFlex flexJustifyBetween mo-flexColumnReverse">
                <div className="displayFlex flexColumn flexCenter footer-about-text">
                    <div className={'footer-about-container'}>
                        <h3 className="mobile-hidden">
                            {ts('about:title')}
                        </h3>
                        <p>
                            {ts('about:sportybe1')}
                            <span className={'bold'}> {ts('about:sportybe2')}</span> {ts('about:sportybe3')}
                        </p>
                    </div>
                </div>
                <div className="footer-about-img">
                    <h3 className="mobile-show">{ts('about:title')}</h3>
                    <img className={'image img-fluid'} src={'/images/searchAbout.jpg'} alt={'SportyBe logo'}/>
                </div>
            </div>
        </div>
    </div>

};

export default FooterAbout;
