import Head from 'next/head';
import React, {useRef} from 'react';
import {StickyContainer} from 'react-sticky';
import {isServer} from 'src/utils';
import styled, {css} from 'styled-components';
import {sizes} from '../../../../styles/mediaQueries';
import useTranslations from "../../../../utils/hooks/useTranslations";
import useDeviceInfo from "../../../../utils/hooks/useDeviceInfo";
import HeaderWrapper from "../header/headerWrapper";
import FooterWrapper from "../header/footerWrapper";
import {useMicro} from "../../../../utils/hooks/useMicro";

export interface BaseLayoutProps extends Without<React.AllHTMLAttributes<HTMLDivElement>, 'as'> {
  // TODO: možná odstranit
  fullWidth?: boolean;
  footerBelowViewport?: boolean;
  pageContainer?: boolean;
  layoutColor?: 'primary' | 'secondary';
  indentTop?: boolean;
  showFooter?: boolean;
  isIndex?: boolean
}

const BaseLayout: React.FC<BaseLayoutProps> = ({
                                                 showFooter = true,
                                                 pageContainer = true,
                                                 layoutColor = 'primary',
                                                 fullWidth = true,
                                                 footerBelowViewport = false,
                                                 indentTop,
                                                 isIndex = false,
                                                 children,
                                                 ...rest
                                               }: BaseLayoutProps) => {
  const {t, ts} = useTranslations();
  const {isApp} = useDeviceInfo();

  const fullWidthMobile = !fullWidth && !isApp;
  const headerHeight = 90;
  const {resourceId} = useMicro();

  return <SC.LayoutWrapper {...rest}>
    {!isServer() && (
      <Head>
        <meta content={window.location.href} property="og:url"/>
      </Head>
    )}
    <StickyContainer>
      <HeaderWrapper isIndex={isIndex && resourceId == null}/>

      <SC.Body $layoutColor={'primary'}>
        <SC.ChildrenWrapper
          $footerBelowViewport={footerBelowViewport}
          $headerHeight={headerHeight}
          $fullWidthMobile={fullWidthMobile}
          $pageContainer={!!pageContainer}
          id="pageFullWidth"
        >
          {children}
        </SC.ChildrenWrapper>

        {/*{!isApp && showFooter && <FooterContainer />}*/}

      </SC.Body>
      <FooterWrapper isIndex={isIndex}/>
    </StickyContainer>
  </SC.LayoutWrapper>;
};

const SC = {
  LayoutWrapper: styled.div`
    overflow: hidden;

    .cursor-pointer {
      cursor: pointer;
      user-select: none;
    }


    .test_title {
      .foo {
        color: green;
      }
    }
  `,
  ChildrenWrapper: styled.main<{
    $fullWidthMobile: boolean;
    $pageContainer: boolean;
    $footerBelowViewport?: boolean;
    $headerHeight: number;
  }>(
    ({$pageContainer, $fullWidthMobile, $headerHeight, $footerBelowViewport}) => css`
      position: relative;
      width: 100%;
      min-height: ${$footerBelowViewport && `calc(100vh - ${$headerHeight}px)`};

      @media (min-width: ${sizes.mDeviceUp}) {
        min-height: ${$footerBelowViewport ? `calc(100vh - ${$headerHeight}px)` : '400px'};
        width: ${$fullWidthMobile ? 'calc(100% - 280px)' : '100%'};
      }

      @media (min-width: ${sizes.mlDeviceUp}) {
        width: ${$fullWidthMobile ? 'calc(97% - 280px)' : '100%'};
      }

      @media (min-width: ${sizes.xlDeviceUp}) {
        width: ${$fullWidthMobile ? 'calc(100% - 436px)' : '100%'};
      }

      @media (min-width: ${sizes.xxlDeviceUp}) {
        width: ${$pageContainer && '1920px'};
        margin: 0 auto;
        padding-right: ${$fullWidthMobile ? '436px' : 0};
      }

      @media print {
        width: auto !important;
        margin: 0;
        padding: 0;
      }

      .hasShadow {
        box-shadow: 0 6px 20px -6px rgba(0, 0, 0, 0.4);
        background: ${({theme}) => theme.backgroundColorPrimary};
        position: relative;
        top: 32px;
        margin: 0 24px 32px;

        .guidepostDefault,
        .filters {
          margin-left: 32px;
          margin-right: 32px;

          @media (max-width: ${sizes.sDevice}) {
            margin-left: 8px;
            margin-right: 8px;

            .catalogItems {
              margin-left: -8px;
              margin-right: -8px;
            }
          }
        }

        @media (max-width: ${sizes.mDevice}) {
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    `
  ),
  HeaderWrapper: styled.div<{ $sticky: boolean; $userBoxVisible: boolean }>(
    ({$sticky, $userBoxVisible}) => css`
      z-index: 103;
      position: relative;
      transform: none !important;
      top: ${$sticky ? '-49px !important' : 0};

      @media (max-width: ${sizes.sDevice}) {
        position: ${$userBoxVisible && $sticky && 'relative !important'};
      }
    `
  ),
  Body: styled.div<{ $layoutColor: 'primary' | 'secondary' }>(
    ({$layoutColor, theme}) => css`
      background-color: ${{primary: theme.backgroundColorPrimary, secondary: theme.backgroundColorSecondary}[
        $layoutColor
        ]};
    `
  ),
};

export default BaseLayout;
