import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOutsideAlerter} from '../../../utils/hooks/useOutsideAlerter';
import {useUser} from '../../../utils/hooks/useUser';
import {Category} from '../../catalog/models';
import {selectFavoriteCategories, selectSuggestCategory} from '../../catalog/reducers/selectors';
import {loadFavoriteCategories, resetSuggestedCategory, suggestCategory} from '../../catalog/actions';
import {useAllCategoriesQuery} from "../../catalog/queries/useAllCategoriesQuery";

export interface ComponentProps {
    label: string,
    className?: string | null,
    defaultValue?: string | null,
    selectCategory: (category: Category) => void
}

const CategorySearchBar: React.FC<ComponentProps> = ({label, className, selectCategory, defaultValue}) => {
    const dispatch = useDispatch();

    const [inputValue, setInputValue] = useState<string>('');
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [enableSearch, setEnableSearch] = useState<boolean>(true);

    const categories = useSelector(selectSuggestCategory);
    const favoriteCategories = useSelector(selectFavoriteCategories);
    const {data, isError, isLoading} = useAllCategoriesQuery();

    // TODO HACK, pls better solution
    const ref = useRef(null);
    const {outsideAlerter} = useOutsideAlerter();

    const {userId} = useUser();

    outsideAlerter(ref, setShowDropdown);

    useEffect(() => {
        if (userId) {
            dispatch(loadFavoriteCategories(userId, 10, 1))
        }

        if (defaultValue != null) {
            setInputValue(defaultValue);
            setEnableSearch(false)
        }
    }, [defaultValue])

    useEffect(() => {
        if (isSelected || !enableSearch) {
            return;
        }

        if (categories.length === 0 && inputValue) {
            setShowDropdown(false);
            return;
        }
        if (categories.length !== 0 && inputValue) {
            setShowDropdown(true);
        }
    }, [categories, inputValue])

    const onClick = () => {
        if (data?.length != 0 || favoriteCategories.length != 0 || categories.length != 0 || userId) {
            setShowDropdown(!showDropdown);
        }
    };

    useEffect(() => {
        setIsSelected(false);
    }, [inputValue])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEnableSearch(true);

        if (e.target.value.length >= 3) {
            dispatch(suggestCategory(e.target.value));
        }

        setInputValue(e.target.value);

        if (e.target.value === '' || e.target.value.length < 3) {
            dispatch(resetSuggestedCategory());
            setShowDropdown(false);
        }
    };


    const select = (category: Category) => {
        setShowDropdown(false);
        setIsSelected(true);
        setInputValue(category.name);

        selectCategory(category);
    }

    return (
        <div className={`${className} location-search-bar col-12`} ref={ref}>
            <span className={'mobile-hidden'}>{label}</span>
            <input
                type={'text'}
                placeholder={'Vyberte sport'}
                onClick={onClick}
                onChange={onChange}
                value={inputValue}
            />
            <div className={'dropdown' + (showDropdown ? ' show' : '')}>
                <ul className={'suggest-list'}>
                    {(favoriteCategories && categories.length === 0 && !inputValue) &&
                        favoriteCategories.map((favoriteCategory) => {
                            return <li key={favoriteCategory.id} className={'favorite'}
                                       onClick={() => select(favoriteCategory)}>{favoriteCategory.name}</li>;
                        })}
                    {categories.length === 0 &&
                        data?.filter(category => !favoriteCategories.find(fc => fc.id === category.id))
                            .map((category) => <li key={category.id}
                                                   onClick={() => select(category)}>{category.name}</li>)}
                    {categories &&
                        categories.map((category) => {
                            return <li key={category.id}
                                       className={favoriteCategories.find(favoriteCategory => favoriteCategory.id === category.id) ? 'favorite' : ''}
                                       onClick={() => select(category)}>{category.name}</li>;
                        })}
                </ul>
            </div>
        </div>
    );
};

export default CategorySearchBar;
