import {useApplicationContext} from "../../common/applicationWrapper/useApplicationContext";

const useDeviceInfo = () => {
  const applicationContext = useApplicationContext();

  const { deviceInfo } = applicationContext.value;

  return {
    ...deviceInfo,
    isIE: deviceInfo.browser === 'IE',
  };
};

export default useDeviceInfo;
