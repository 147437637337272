import React, {useEffect, useState} from 'react';

import DateAndTimeBar from '../../common/components/dateAndTimeBar';
import useTranslations from '../../../utils/hooks/useTranslations';
import {Namespace} from '../../../lib/i18n/types';
import {DynamicTranslations} from '../../../lib/i18n/dynamicTranslations';
import LocationSearchBar from '../../common/components/locationSearchBar';
import CategorySearchBar from '../../common/components/categorySearchBar';
import {useDispatch, useSelector} from 'react-redux';
import {selectSearchFormData} from '../reducers/selectors';
import {useRouting} from '../../../routing/utils/useRouting';
import {setSearchFormData} from '../actions';
import {Category, Location} from '../../catalog/models';
import {initialState} from '../reducers';

const SearchForm: React.FC = () => {

  const {routes, router} = useRouting();
  const {ts} = useTranslations();
  const searchFormData = useSelector(selectSearchFormData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchFormData({}))
  }, [])

  const onClick = () => {
    router.push(routes.search.url({
      location: searchFormData.location,
      category: searchFormData.category,
      when: searchFormData.when?.toISOString()
    }));
  };

  const selectLocation = (location: Location) => {
    dispatch(setSearchFormData({
      ...searchFormData,
      location: location.id
    }))
  };

  const selectCategory = (category: Category) => {
    dispatch(setSearchFormData({
      ...searchFormData,
      category: category.id
    }))
  };

  const selectDateTime = (date: Date) => {
    dispatch(setSearchFormData({
      ...searchFormData,
      when: date
    }))
  }

  useEffect(() => {
    return () => {
      dispatch(setSearchFormData(initialState.form))
    };
  }, [])


  return <div className={"displayFlex mo-flexColumn"}>
    <LocationSearchBar key={'search-form-category-search-bar'}
                       label={'Kde chcete sportovat?'}
                       className={'searchForm__item'}
                       selectLocation={selectLocation}/>
    <CategorySearchBar label={'Jaký sport vás zajímá?'}
                       className={'searchForm__item'}
                       selectCategory={selectCategory}/>
    <DateAndTimeBar label={ts('datepicker:title')}
                    selectDateTime={selectDateTime}/>
    <button className={'button-default button-submit-search'} onClick={onClick}>
      <span>{ts('common:searchTitle')}</span>
    </button>
  </div>
};

export default SearchForm;
