import {useEffect, useState} from 'react'

export const useDebounce = (fn: any, milliSeconds: number) => {
    const [value, setValue] = useState<any>(null);
    const [debouncedValue, setDebouncedValue] = useState(null);

    useEffect(() => {
        if (debouncedValue == null) {
            return
        }
        fn(debouncedValue)
        setDebouncedValue(null)
    }, [debouncedValue])

    useEffect(() => {
        if (!value) {
            return;
        }
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, milliSeconds);

        return () => {
            clearTimeout(handler);
        };
    }, [value, milliSeconds]);

    return {debouncedValue, setValue};
};