import {Action, ActionFunction} from '../../../types';

import createReduxAction from '../../common/actionCreator';
import {
    CategoryFinderPayload, OpenStatusData,
    SearchAvailableSportgroundForReservationPayload,
    SearchFilter,
    SportgroundFinderPayload
} from '../models';
import {ArticleSearchPayload} from '../../article/models';

export function getClosestLocation(lat: number, lng: number): ActionFunction<Action> {
    return createReduxAction({
        type: 'GET_CLOSEST_LOCATION_ACTION',
        promise: ({api}) => api.get('GET_CLOSEST_LOCATION', {urlParams: {lat, lng}}),
    });
}

export function suggestLocation(value: string): ActionFunction<Action> {
    return createReduxAction({
        type: 'SUGGEST_LOCATION_ACTION',
        promise: ({api}) => api.get('SUGGEST_LOCATION_ACTION', {urlParams: {searchValue: value, limit: 10, page: 0}}),
    });
}

export function loadFavoriteLocations(
    accountId: string,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'LOAD_FAVORITE_LOCATIONS_ACTION',
        promise: ({api}) =>
            api.get('GET_FAVORITE_LOCATIONS', {
                urlParams: {size: limit, page},
                urlParts: [accountId]
            }),
    });
}

export function suggestCategory(value: string): ActionFunction<Action> {
    return createReduxAction({
        type: 'SUGGEST_CATEGORY_ACTION',
        promise: ({api}) => api.post('CATEGORY_CACHE_FINDER', {urlParams: {sort: 'name,asc'},data: {name: value}}),
    });
}

export function resetSuggestedCategory(): Action {
    return {
        type: 'RESET_SUGGESTED_CATEGORY',
    };
}

export function resetSuggestedLocation(): Action {
    return {
        type: 'RESET_SUGGESTED_LOCATION',
    };
}

export function loadFavoriteCategories(
    accountId: string,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'LOAD_FAVORITE_CATEGORIES_ACTION',
        promise: ({api}) =>
            api.get('GET_FAVORITE_CATEGORIES', {
                urlParams: {size: limit, page,sort:'name,asc'},
                urlParts: [accountId]
            }),
    });
}


export function searchAvailableSportgroundForReservation(
    payload: SearchAvailableSportgroundForReservationPayload,
    sort: string | undefined,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'SEARCH_AVAILABLE_SPORTGROUND_FOR_RESERVATION_ACTION',
        promise: ({api}) =>
            api.post('SEARCH_AVAILABLE_SPORTGROUND_FOR_RESERVATION', {
                urlParams: {size: limit, page, sort},
                data: payload
            }),
    });
}

export function searchyCatalogSportgrounds(
  payload: SearchAvailableSportgroundForReservationPayload,
  sort: string | undefined,
  limit: number,
  page: number
): ActionFunction<Action> {
  return createReduxAction({
    type: 'SEARCH_CATALOG_SPORTGROUNDS_ACTION',
    promise: ({api}) =>
      api.post('SEARCH_CATALOG_SPORTGROUNS', {
        urlParams: {size: limit, page, sort},
        data: payload
      }),
  });
}

export function getSearchInformation(
    categoryId: string | null | undefined,
    locationId: string | null | undefined
): ActionFunction<Action> {

    return createReduxAction({
        type: 'GET_SEARCH_INFORMATION_ACTION',
        promise: ({api}) =>
            api.get('GET_SEARCH_INFORMATION', {
                urlParams: {categoryId, locationId},
            }),
    });
}


export function getSearchParameters(
    categoryId: string | null | undefined
): ActionFunction<Action> {

    return createReduxAction({
        type: 'GET_SEARCH_PARAMETERS_ACTION',
        promise: ({api}) =>
            api.get('GET_SEARCH_PARAMETERS', {
                urlParams: {categoryId},
            }),
    });
}


export function setSearchFilter(data: SearchFilter): Action {
    return {
        type: 'SET_SEARCH_FILTER_DATA',
        data
    };
}

export function resetSporgroundSearch(): Action {
    return {
        type: 'RESET_SPORTGROUND_SEARCH',
    };
}

export function findSportgroundForCategory(
    payload: SportgroundFinderPayload,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'GET_SPORTGROUND_FOR_CATEGORY_ACTION',
        promise: ({api}) =>
            api.post('SEARCH_CATALOG_SPORTGROUNS', {
                urlParams: {size: limit, page},
                data: payload
            }),
    });
}

export function loadCategoryCatalog(
    payload: CategoryFinderPayload,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'GET_CATEGORY_CATALOG_ACTION',
        promise: ({api}) =>
            api.post('CATEGORY_CACHE_FINDER', {
                urlParams: {size: limit, page},
                data: payload
            }),
    });
}

export function setCategoryCatalogFilter(data: CategoryFinderPayload): Action {
    return {
        type: 'SET_CATEGORY_CATALOG_FINDER_FILTER',
        data
    };
}

export function setSportgroundOpenStatus(data: OpenStatusData): Action {
    return {
        type: 'SET_SPORTGROUND_DETAIL_OPEN_STATUS',
        data
    };
}

export function setSportgroundOpenHourList(data: { [key: string]: string[] }): Action {
    return {
        type: 'SET_SPORTGROUND_DETAIL_OPEN_HOUR_LIST',
        data
    };
}

export function findArticlesForCategory(
    payload: ArticleSearchPayload,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'FIND_ARTICLES_FOR_CATEGORY_ACTION',
        promise: ({api}) =>
            api.post('ARTICLE_FINDER', {
                urlParams: {size: limit, page},
                data: payload
            }),
    });
}

export function findRevewsSportgroundDetail(
    sportgroundId: string,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'GET_SPORTGROUND_REVIEWS_ACTION',
        promise: ({api}) =>
            api.get('SPORTGROUND_REVIEW', {
                urlParams: {size: limit, page},urlParts: [sportgroundId]
            }),
    });
}
