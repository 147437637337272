import styled, {css} from "styled-components";
import {sizes} from "../../../../styles/mediaQueries";

export const SC = {
  Header: styled.div<{
    $isIndex: boolean
  }>(
    ({theme, $isIndex}) => css`
      .dev-banner-info {
        text-align: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #f1416c;
        color: white;
        font-weight: 700;
        font-size: 12px;
        z-index: 1080;
        font-family: Inter, Helvetica, "sans-serif";
        padding: 1rem;
      }

      &.index {
        background: url('/images/homepageHeader.png') no-repeat 50% / cover;
        background-size: cover;
        -o-object-fit: cover;
        object-fit: cover;
        height: 100vh;
        flex-shrink: 0;
        background-position-y: top;

        .searchForm {
          color: #fff;
          margin-top: 25vh;
          padding: 0 1rem;
          @media (max-width: ${sizes.mDevice}) {
            margin-top: 5vh;

          }

          p {
            width: 45vw;
            font-family: HelveticaNeue, serif;
            font-weight: 500;
            font-size: 48px;
            line-height: 58px;
            color: #fff;
            padding-bottom: 1.5rem;

            @media (max-width: ${sizes.mDevice}) {
              width: 100%;
              font-weight: 400;
              margin-bottom: 27px;
              font-size: 22px;
              line-height: 30px;
              text-align: center;
            }

          }

          &__item {
            border-radius: 4px;
            color: #000;
            margin-left: 5px;
            background: #fff;
            width: 27rem;
            padding: 1rem 0.5rem .5rem 2.5rem;
            height: 8.4rem;
            position: relative;

            @media (max-width: ${sizes.mDevice}) {
              width: 100%;
              margin-bottom: 1rem;
              height: 60px;
              padding: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 2rem;
            }

            :first-child {
              margin: 0;
              @media (max-width: ${sizes.mDevice}) {
                margin-left: 5px;
                margin-bottom: 1rem;
              }
            }

            label, span {
              line-height: 19px;
              letter-spacing: .012em;
              color: #141126;
              display: block;
              font-family: Nunito, serif;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
            }

            input {
              height: 2.5em;
              padding: 0;
              background: #fff url('/icons/Vector 1.svg') no-repeat;
              background-position-x: 95%;
              background-position-y: 50%;
              background-size: 14px;
              border: none;
              font-family: Nunito, serif;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 25px;
              color: #141126;
              margin-top: 0.5rem;
              @media (max-width: ${sizes.mDevice}) {
                background: #fff;
                font-size: 16px;
              }

            }

            .dropdown {
              display: none;
              background-color: #fff;
              width: 16em;
              position: relative;
              left: -12px;
              padding: .5rem 1rem;
              margin-top: .5rem;
              border-radius: 5px;
              z-index: 100;
              box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
              max-height: 16rem;
              overflow: auto;
            }

            &__invisible {
              top: 0;
              position: absolute;
              opacity: 0;
              width: 100%;
              left: 0;
              height: 100%;

              input {
                display: block;
                height: 8.5rem;
                position: relative;
                margin: 0;
                padding: 0;
              }
            }

            &__date {
              margin-top: 1.8rem;
              position: relative;

              @media (max-width: ${sizes.mDevice}) {
                margin-top: 0;
              }

              &:after {
                float: right;
                background-image: url("/icons/Vector 1.svg");
                content: "";
                width: 14px;
                height: 14px;
                right: 1rem;
                //height: 100%;
                margin: 0;
                position: absolute;
                bottom: 0;
                background-size: contain;
                background-repeat: no-repeat;

                @media (max-width: ${sizes.mDevice}) {
                  //bottom: 1.8rem;
                  right: 1.4rem;
                }
              }

              &__day {
                font-size: 15px;
                margin-right: 0.2em;
                padding-right: 7px;
              }

              &__date {
                font-size: 18px;
                font-weight: bold;
                padding-right: 5px;
              }

              &__in {
                font-size: 20px;
                padding-right: 5px;
              }

              &__hour {
                font-size: 20px !important;
                font-weight: bold !important;
              }
            }
          }

          .button-submit-search {
            height: 8.4rem;
            background: #79D231;
            margin-left: 5px;
            padding: 0 20px;
            border: none;
            display: flex;
            justify-content: center;
            flex-direction: column;

            @media (max-width: ${sizes.mDevice}) {
              height: 60px;
              width: 100%;
            }

            span {
              font-family: Nunito, serif;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 25px;
              color: #FFFFFF;

              @media (max-width: ${sizes.mDevice}) {
                font-size: 16px;
                text-align: center;
                width: 100%;

              }

              &:before {
                background-image: url("/icons/find.svg");
                height: 20.5px;
                width: 21px;
                display: inline-flex;
                content: "";
                border-bottom: 0;
                position: relative;
                top: 0.2rem;
                left: -1rem;
              }
            }
          }

          .location-search-bar {

            .location-search-bar-date {
              padding: 14px;
              font-family: Nunito, serif;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0.015em;
              color: #141126;
              background: #FFFFFF;
              box-sizing: border-box;
              border-radius: 4px;
              height: 48px;
              width: 100%;
              border: none;
              background: #FFFFFF url('/icons/Vector 1.svg') no-repeat;
              background-position-x: 94.5%;
              background-position-y: 1.3em;
              background-size: 14px;
              @media (max-width: ${sizes.mDevice}) {
                padding: 17px;
              }
            }

            .loading-indicator {
              display: none;

              &.show {
                display: inline-block;
              }
            }

            .dropdown {
              display: none;
              background-color: #fff;
              width: 100%;
              padding: 0.5rem 1rem;
              margin-top: 1rem;
              border-radius: 5px;
              z-index: 100;
              box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
              max-height: 16rem;
              overflow: auto;
              position: absolute;
              left: 0;
              right: 0;
              top: 92%;

              @media (max-width: ${sizes.mDevice}) {
                width: 100%;
                position: absolute;
                left: 0;
                margin-top: 0.6rem;
              }

              &.show {
                display: block;
              }

              .suggest-list {
                font-family: Nunito, serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.012em;
                color: #141126;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                  border-bottom: 1px solid #DEDEDE;
                  padding: 0.8rem 0;
                  cursor: pointer;

                  &.favorite {
                    &::after {
                      content: "";
                      background-image: url("/images/heart1.png");
                      background-size: contain;
                      position: absolute;
                      height: 15px;
                      width: 18px;
                      right: 1rem;
                    }
                  }
                }

                li:last-child {
                  border: none;
                }
              }

              .use-my-location {
                display: inline-block;
                font-family: Nunito, serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px !important;
                line-height: 20px;
                letter-spacing: 0.012em;
                color: #141126;
                padding-top: 0.8rem;
                padding-bottom: 0.8rem;
                padding-left: 1.2rem;
                cursor: pointer;
                width: 100%;

                &:before {
                  background-image: url("/icons/location.svg");
                  height: 15px;
                  width: 18px;
                  left: -8px;
                  top: 3px;
                  display: inline-block;
                  content: "";
                  border-bottom: 0;
                  position: relative;
                  margin-right: 0.2em;
                }

                .indicator {
                  display: none;
                  border: 2px solid #f3f3f3;
                  border-radius: 50%;
                  border-top: 2px solid #79D231;
                  width: 15px;
                  height: 15px;
                  -webkit-animation: spin 2s linear infinite; /* Safari */
                  animation: spin 2s linear infinite;
                  margin-left: 2rem;
                  top: 4px;
                  position: relative;

                  &.show {
                    display: inline-block;
                  }
                }
              }
            }
          }

        }

        .contactBar {
          margin-top: 15%;
          font-family: Nunito, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #fff;

          .contact-email-phone {
            display: flex;
            justify-content: space-between;
            @media (max-width: ${sizes.mDevice}) {
              margin-bottom: 3rem;
            }

          }

          .phone {
            color: #fff;
            text-decoration: none;

            &:before {
              background-image: url("/icons/phone.svg");
              height: 16px;
              width: 16px;
              display: inline-flex;
              content: "";
              border-bottom: 0;
              position: relative;
              left: -10px;
              top: 3px;
            }
          }

          @media (max-width: 1024px) and (min-width: 769px) {
            .phone {
              position: relative;
              bottom: -2px;
              left: 39px;

              &:before {
                display: flex;
                left: -27px;
                top: 18px;
              }
            }
          }

          .mail {
            color: #fff;
            margin-left: 4rem;
            text-decoration: none;

            &:before {
              background-image: url("/icons/mail.svg");
              height: 15px;
              width: 20px;
              display: inline-flex;
              content: "";
              border-bottom: 0;
              position: relative;
              left: -10px;
              top: 3px;
            }
          }

          @media (max-width: 1024px) and (min-width: 769px) {
            .mail {
              position: relative;
              left: 41px;

              &:before {
                display: flex;
                left: -33px;
                top: 17px;
              }
            }
          }

          .social {

            @media (max-width: ${sizes.mDevice}) {
              flex-direction: column;
            }

            .desc {
              line-height: 20px;
              padding-right: 2rem;

              @media (max-width: ${sizes.mDevice}) {
                margin-bottom: 1.5rem;
              }
            }

            .instagram {

              &:before {
                background-image: url("/icons/instagram.svg");
                height: 24px;
                width: 24px;
                display: inline-flex;
                content: "";
                border-bottom: 0;
                position: relative;
              }
            }

            @media (max-width: 1024px) and (min-width: 769px) {
              .instagram {
                &:before {
                  top: 6px;
                  left: -10px;
                }
              }
            }

            .facebook {
              &:before {
                background-image: url("/icons/facebook.svg");
                height: 22px;
                width: 11px;
                display: inline-flex;
                content: "";
                border-bottom: 0;
                position: relative;
                left: 11px;
                top: -2px;
              }
            }

            @media (max-width: 1024px) and (min-width: 769px) {
              .facebook {
                &:before {
                  left: -3px;
                  top: 4px;
                }
              }
            }
          }
        }

      }
    }

    a {
      text-decoration: none;
      color: ${$isIndex ? 'white' : 'black'};
    }

    a:hover {
      text-decoration: underline;
    }

    .header-wrapper {
      padding: 24px 11px 24px 13px;
      background: ${$isIndex ? 'transparent' : 'white'}; //todo change to transparent when index is done
      font-weight: 300;
      font-size: 16px;
      @media (max-width: ${sizes.sDeviceUp}) {
        padding: 35px 11px 17px 13px;
      }
    }

    .navigation {
      width: 30vw;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: ${theme.fontSize[200]};
      font-family: Nunito, serif;
    }

    .button-hamburger {
      height: 16px;
      width: 14px;
      padding: 10px 13px;
      margin-left: 1.5rem;


      @media (max-width: ${sizes.sDeviceUp}) {
        width: 45px;
        height: 42px;
      }

      img {
        @media (max-width: ${sizes.sDeviceUp}) {
          filter: ${$isIndex ? '' : 'invert(100%)'};
        }
      }
    }


    .is-loggedHamburger {
      @media (max-width: ${sizes.sDeviceUp}) {
        padding-top: 3px;
      }
    }

    .button-navigation {
      background: ${$isIndex ? 'hsla(0, 0%, 100%, .3)' : '#dedede'};
      backdrop-filter: blur(21px);

    }

    .button-navigation:hover {
      background: #79d231 !important;
    }

    .button-registration {
      background: hsla(0, 0%, 100%, .3);
      border: 1px solid #dedede;

      @media (max-width: ${sizes.sDeviceUp}) {
        width: fit-content;
        padding: 10px 15px;
      }
    }

    .button-registration:before {
      background-image: url(/icons/register.svg);
      height: 18px;
      width: 16px;
      display: inline-block;
      content: "";
      border-bottom: 0;
      position: relative;
      left: -10px;
      top: 3px;
      filter: ${$isIndex ? '' : 'invert(100%)'};
      @media (max-width: ${sizes.sDeviceUp}) {
        left: 0;
      }
    }

    .mobile-login-wrapper {
      position: relative;
    }

    .login-wrapper {
      color: ${$isIndex ? 'white' : 'black'};
      display: flex;
      justify-content: space-between;
    }

    .navigation-login {
      margin: 0 2rem 0 0;
    }

    .navigation-dropdown {
      position: absolute;
      background: #fff;
      padding: 0.5rem 1rem;
      z-index: 100;
      right: 0;
      text-align: center;
      margin-right: 1.7rem;
      border-radius: 5px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      top: 100%;

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;

          a {
            color: black;
            text-decoration: none;
            font-weight: 300;
            font-size: 15px;
            line-height: 36px;
            letter-spacing: .015em;
            white-space: nowrap;
            cursor: pointer;
          }
        }
      }
    }

    .about--header {

      font-family: Nunito, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */
      letter-spacing: 0.015em;
      color: #000000;
      position: relative;

      .avatar {
        position: relative;
        background: linear-gradient(0deg, #79D231, #79D231), linear-gradient(63.45deg, #A8D91C -1.43%, #2DC753 94.42%);
        border-radius: 100px;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        display: inline-block;
        margin-right: 1rem;

        @media (max-width: ${sizes.sDeviceUp}) {
          width: 40px;
          height: 40px;
          margin-right: 0;

        }


        .str {
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-47%) translateX(-42%);
          color: #ffffff;
          font-family: Nunito, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          text-align: center;

          @media (max-width: ${sizes.sDeviceUp}) {
            font-size: 16px;
            transform: translateY(-47%) translateX(-49%);


          }
        }
      }

      figure {
        -webkit-border-radius: 50%;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        overflow: hidden;
        display: inline-block;

        img {
          width: 30px;
          height: 30px;
          margin: 0 auto;
          display: block;
          position: relative;
          top: 2px;
          border-radius: 50%;
        }
      }

      .name {
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.015em;
        color: ${$isIndex ? 'white' : 'black'};

        display: inline-block;
        position: relative;
        border-right: 1px solid #E8E8E8;
        padding-right: 1em;
        margin-right: 1em;
        padding-left: 0.5em;
      }

      .dropdown {
        display: inline-block;
        position: relative;
        top: -8px;
        color: ${$isIndex ? 'white' : 'black'};
        margin-right: 2rem;

        button {
          border: none;
          box-sizing: border-box;
          border-radius: 4px;
          font-family: Nunito, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          /* identical to box height */
          letter-spacing: 0.015em;
          color: #141126;
          background: transparent url("/icons/Vector 1.svg") no-repeat;
          background-position-x: 95%;
          background-position-y: 8px;
          padding-right: 1.5em;

          @media (max-width: $md) {
            background-position-x: 95%;
          }
        }

        &__content {
          display: none;
          position: absolute;
          background-color: #ffffff;
          min-width: inherit !important;
          right: 0;
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          z-index: 107;
          text-align: left;
          border-radius: 4px;

          &__show {
            display: block;
          }

          ul {
            padding: 1rem 0 !important;
            margin: 0;

            li {
              padding: 0;
              text-decoration: none;
              display: block;

              a {
                font-family: Nunito, serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                letter-spacing: 0.012em;
                color: #141126;
                width: 100%;
                display: block;
                white-space: nowrap;
                padding: 0.2rem 1rem !important;
              }
            }
          }
        }
      }
    }

    @media (max-width: ${sizes.mDevice}) {
      .about--header {
        float: right;
      }
    }
    `),
};
