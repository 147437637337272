import React from 'react';
import useTranslations from "../../../../utils/hooks/useTranslations";
import getNextConfig from "next/config";
import useRoutes from "../../../../routing/utils/useRoutes";
import RouteLink from "../../../app/components/RouteLink";
import Link from "next/link";
import Image from "next/image";

const FooterContacts: React.FC = () => {
  const {phone, email, instagram, facebook, twitter} = getNextConfig().publicRuntimeConfig.parameters;
  const routes = useRoutes();
  const {ts} = useTranslations();

  return <div className={'footer-contacts'}>
    <div className={'container'}>
      <RouteLink to={routes.index.url()} className={'logo'}>
        <img alt="" src={'/images/logo.png'}/>
      </RouteLink>
      <div className={'displayFlex footer-info-wrapper'}>
        <div className="footer-info">
          <h4>Spojte se s námi</h4>
          <p>Hotline ve všední dny od 8:00 do 20:00</p>
          <ul className={"contact"}>
            <li>
              <a href={`tel:${phone}`} className="phone">
                {phone}
              </a>
            </li>
            <li>
              <a href={`mailto:${email}`} className="footer-info-mail">
                {email}
              </a>
            </li>
          </ul>
          <h4>Sledujte nás</h4>
          <div className={'footer-info-social'}>
            <RouteLink to={instagram} target="_blank">
              <Image
                src="/icons/Instagram.png"
                className={'socialIcon'}
                alt={'instagram'}
                width={42}
                height={42}
                loading={'lazy'}
              />
            </RouteLink>
            <RouteLink to={twitter} target="_blank">
              <Image
                src="/icons/Twitter.png"
                className={'socialIcon'}
                alt={'twitter'}
                width={42}
                height={42}
                loading={'lazy'}
              />
            </RouteLink>
            <RouteLink to={facebook} target="_blank">
              <Image
                src="/icons/Facebook.png"
                className={'socialIcon'}
                alt={'facebook'}
                width={42}
                height={42}
                loading={'lazy'}
              />
            </RouteLink>
          </div>
        </div>
        <div className="footer-info">
          <h4 className={'info-header'}>Pro zákazníky</h4>
          <ul>
            <li>
              <RouteLink to={routes.login.url()}>
                Přihlášení
              </RouteLink>
            </li>
            <li>
              <RouteLink to={routes.register.url()}>
                Registrace
              </RouteLink>
            </li>
            <li>
              <RouteLink to={routes.map.url()}>
                Mapa sportovišť
              </RouteLink>
            </li>
            <li>
              <RouteLink to={routes.catalogCategories.url()}>
                Sporty a aktivity
              </RouteLink>
            </li>
          </ul>
        </div>
        <div className="footer-info">
          <h4 className={'info-header'}>Pro partnery</h4>
          <ul>
            <li>
              <RouteLink to={routes.partnerPrices.url()}>
                Ceník
              </RouteLink>
            </li>
            <li>
              <RouteLink to={routes.forSportground.url()}>
                Prezentace
              </RouteLink>
            </li>
          </ul>
        </div>
        <div className="footer-info">
          <h4 className={'info-header'}>O nás</h4>
          <ul>
            <li>
              <RouteLink to={routes.contact.url()}>
                Kontakt
              </RouteLink>
            </li>
            <li>
              <RouteLink to={routes.articles.url()}>
                Blog
              </RouteLink>
            </li>
          </ul>
        </div>
        <div className="footer-info">
          <h4 className={'info-header'}>Právní dokumenty</h4>
          <ul>
            <li>
              <RouteLink to={routes.conditions.url()}>
                Obchodní podmínky
              </RouteLink>
            </li>
            <li>
              <RouteLink to={routes.cookies.url()}>
                Cookies
              </RouteLink>
            </li>
            <li>
              <RouteLink to={routes.gdpr.url()}>
                Ochrana osobních údajů
              </RouteLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={'displayFlex flexSpaceBetween footer-contact-signature'}>
        <div className="text-start">
          <RouteLink className={'owner'}>2024 SportyBe.com</RouteLink>
        </div>
        <div className="text-end">
          <RouteLink to={routes.links.etyka} className={'developer'}>
            ETYKA
          </RouteLink>
        </div>
      </div>
    </div>
  </div>

};

export default FooterContacts;
