import styled, {css} from "styled-components";
import getBodyTextSizeTokens from "@ds/components/typography/bodyText/tokens/getBodyTextSizeTokens";
import {sizes} from "../../../../styles/mediaQueries";
import {M_WIDTH} from "../../../app/constants";

export const SC = {
  Footer: styled.div(
    ({theme}) => css`

      a {
        color: black;
      }

      a:hover {
        text-decoration: underline;
      }

      .footer-about {
        background-color: #f5f4f7;
        padding-top: 10em;
        padding-bottom: 12em;
        margin-top: 10rem;

        @media (max-width: ${sizes.sDeviceUp}) {
          padding-top: 3em;
          padding-bottom: 3em;
        }

        img {
          min-width: 658px;
          min-height: 292px;
          @media (max-width: ${sizes.sDeviceUp}) {
            min-width: 0;
            min-height: 0;
          }

        }
      }
    }


    .footer-top-cities {
      padding-top: 8rem;
      padding-bottom: 8rem;
      position: relative;
      @media (max-width: ${sizes.sDeviceUp}) {
        padding-top: 3rem;
        width: 97vw;
      }

      .title {
        width: 66%;
        @media (max-width: ${sizes.sDeviceUp}) {
          width: 100%;
        }
      }

      .city-block-wrapper {
        margin-top: 2rem;
        @media (max-width: ${sizes.sDeviceUp}) {
          width: 95vw;
          justify-content: space-between;
          display: flex;
        }

      }

      .city-block {
        width: 200px;
        height: 72px;
        margin-right: 2rem;
        background: #fff;
        border-radius: 4px;
        text-decoration: none;
        font-weight: 400;
        font-size: ${theme.fontSize[200]};
        padding-left: 24px;
        color: #141126;
        text-align: left;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        @media (max-width: ${sizes.sDeviceUp}) {
          margin-right: 0;
          width: 45vw;
        }
      }

      .citi-block-vector {
        position: absolute;
        right: 2.5rem;
        height: 11px;
        width: 11px;
        transform: rotate(-90deg);
      }
    }

    .footer-top-cities-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: ${sizes.sDeviceUp}) {
        flex-direction: column-reverse;
      }
    }

    .footer-top-cities:before {
      top: 0;
      bottom: 0;
      left: -1000px;
      background: #f5f4f7;
      //height: 43.4375em;
      display: block;
      content: "";
      position: absolute;
      z-index: -1;
      right: 17vw;
      @media (max-width: ${sizes.sDeviceUp}) {
        right: 0;
      }
    }


    .footer-about-container {
      width: 100%;

      p {
        width: 21vw;
        @media (max-width: ${sizes.sDeviceUp}) {
          margin-top: 3rem;
          width: 100%;
        }
      }
    }

    .footer-about-img {
      flex: 1;
      text-align: end;
    }

    .footer-contacts {
      padding-top: 5rem;
      padding-bottom: 4rem;
      background: #f5f4f7;

      .logo {
        display: block;
        margin: 2rem 3rem;
      }
    }


    .info-header {
      font-weight: bold;
    }

    .footer-info-wrapper {
      margin-bottom: 10rem;

      @media (max-width: ${sizes.sDeviceUp}) {
        flex-direction: column;
        position: relative;
      }

      .footer-info {
        text-align: left;
        width: 25%;
        line-height: 35px;
        font-size: 15px;
        padding: 0 4rem;

        @media (max-width: ${sizes.sDeviceUp}) {
          width: 100%;
          text-align: center;
          margin-bottom: 3rem;
          font-size: ${theme.fontSize[100]};

        }

        h4 {
          margin: 1rem 0;
        }

        ul {
          margin-top: 1rem;

          li {
            a {
              font-size: 15px;
            }
          }
        }

        .phone {
          font-size: 15px;
          padding-bottom: 0;
        }

        .footer-info-mail {
          text-decoration: underline;
          font-weight: 600;
          font-size: 15px;
          padding: 0;

        }

        .footer-info-social {
          margin-top: 0;
        }

      }
    }

    .footer-contact-signature {
      a {
        font-weight: 200;
        font-size: ${theme.fontSize[100]};
      }

      .developer:before {
        background-image: url(/icons/heart_1.svg);
        height: 18px;
        width: 20px;
        display: inline-block;
        content: "";
        border-bottom: 0;
        position: relative;
        left: -3px;
        top: 5px;
      }
    }

    .footer-promo {
      background: #141126;
      padding-top: 119px;
      padding-bottom: 124px;
      text-align: center;
      position: relative;

      @media (max-width: ${sizes.sDeviceUp}) {
        padding: 6rem 0 4rem 0;
      }

      .logo {
        position: absolute;
        top: -81px;
        right: 0;
        left: 0;
      }

      h1 {
        color: white;
        padding-bottom: 1em;
        @media (max-width: ${sizes.sDeviceUp}) {
          font-size: ${theme.fontSize[600]} !important;
        }
      }

      .applicationGroup {
        padding-bottom: 2em;
        text-align: center;
        justify-content: center;
        display: flex;

        @media (max-width: ${sizes.sDeviceUp}) {
          width: 100vw;
        }

        .applicationWrap {
          width: 60%;
          display: flex;
          justify-content: space-evenly;
          @media (max-width: ${sizes.sDeviceUp}) {
            width: 100vw;
            flex-direction: column;
            position: relative;
          }
        }

        .application {
          padding: 1.0rem 3em 2rem 3em;
          background: rgba(65, 56, 90, 0.31);
          backdrop-filter: blur(21px);
          border-radius: 5px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #FFFFFF;
          text-decoration: none;
          text-align: center !important;

          @media (max-width: ${sizes.sDeviceUp}) {
            width: 93%;
            margin-bottom: 2rem;
          }

          &__ios {
            &:before {
              background-image: url("/icons/apple 1.svg");
              height: 31px;
              width: 32px;
              display: inline-block;
              content: "";
              border-bottom: 0;
              position: relative;
              left: -1em;
              top: 0.5em;
            }
          }

          &__android {
            &:before {
              background-image: url("/icons/android 1.svg");
              height: 31px;
              width: 32px;
              display: inline-block;
              content: "";
              border-bottom: 0;
              position: relative;
              left: -1em;
              top: 0.5em;
            }
          }
        }

      }


      h1 {
        font-weight: 400;
        font-size: ${theme.fontSize[750]};
        line-height: 48px;
        margin-bottom: 3px;
      }

      h3 {
        font-weight: 400;
        font-size: ${theme.fontSize[600]};
        line-height: 33px;
      }

      p {
        font-size: ${theme.fontSize[300]};
        line-height: 26px;
        font-weight: 400;

        .bold {
          font-weight: 600;
        }
      }

    `),
};
