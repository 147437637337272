import React, { useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import useGeolocation from '../../../utils/hooks/useGeolocation';
import {useOutsideAlerter} from '../../../utils/hooks/useOutsideAlerter';
import {Location} from '../../catalog/models';
import {useUser} from '../../../utils/hooks/useUser';
import {
    getClosestLocation,
    loadFavoriteLocations,
    resetSuggestedLocation,
    suggestLocation
} from '../../catalog/actions';
import {selectClosestLocation, selectFavoriteLocations, selectSuggestAddress} from '../../catalog/reducers/selectors';
import {setCoordinates} from '../../app/actions/shared';
import {useDebounce} from '../../../utils/hooks/useDebounce';

export interface ComponentProps {
    label: string,
    defaultValue?: string | null,
    className?: string | null,
    selectLocation: (location: Location) => void
}

const LocationSearchBar: React.FC<ComponentProps> = ({label, className, selectLocation, defaultValue}) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
    const [loadingSuggestLocation, setLoadingSuggestLocation] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [enableSearch, setEnableSearch] = useState<boolean>(true);

    const dispatch = useDispatch();
    const [geolocation, getLocation] = useGeolocation();
    const closestLocation = useSelector(selectClosestLocation);
    const locations = useSelector(selectSuggestAddress);
    const favoriteLocations = useSelector(selectFavoriteLocations);

    const {setValue: debounceSuggest} = useDebounce((value: string) => {
        if (value && value.length >= 3 && enableSearch) {
            dispatch(suggestLocation(value));
        }
    }, 1000)


    const [inputValue, setInputValue] = useState<string>('');

    // TODO HACK, pls better solution
    const ref = useRef(null);
    const {outsideAlerter} = useOutsideAlerter();

    outsideAlerter(ref, setShowDropdown);

    const {userId} = useUser();

    useEffect(() => {
        dispatch(resetSuggestedLocation());

        if (userId) {
            dispatch(loadFavoriteLocations(userId, 10, 1))
        }

        if (defaultValue != null) {
            setInputValue(defaultValue);
            setEnableSearch(false)
        }

    }, [defaultValue])


    useEffect(() => {
        if (geolocation) {
            dispatch(setCoordinates({lng: geolocation?.lng, lat: geolocation?.lat}));
            dispatch(getClosestLocation(geolocation?.lat, geolocation?.lng));
        }
    }, [geolocation]);

    useEffect(() => {
        if (!loadingLocation) {
            return
        }

        if (closestLocation) {
            setInputValue(closestLocation.name);
        }

        setLoadingLocation(false);
        setShowDropdown(false);

        if (closestLocation) {
            selectLocation(closestLocation);
            setIsSelected(true);
        }
    }, [closestLocation]);

    const onClick = () => {
        setShowDropdown(!showDropdown);
    };

    const loadLocation = async () => {
        setLoadingLocation(true);
        getLocation();
    };

    useEffect(() => {
        if (isSelected) {
            return;
        }

        if (locations.length === 0 && inputValue) {
            setShowDropdown(false);
            return;
        }

        if (locations.length !== 0 && inputValue) {
            setLoadingSuggestLocation(false);
            setShowDropdown(true);
        }
    }, [locations, inputValue])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        setEnableSearch(true);
        setIsSelected(false);

        setInputValue(e.target.value);

        debounceSuggest(e.target.value)

        if (e.target.value.length >= 3) {
            setLoadingSuggestLocation(true);
            return;
        }

        if (e.target.value === '' || e.target.value.length < 3) {
            dispatch(resetSuggestedLocation());
            setShowDropdown(false);
            setLoadingSuggestLocation(false);
        }
    };


    const select = (location: Location) => {
        setShowDropdown(false);
        setIsSelected(true);
        setInputValue(location.name)
        selectLocation(location);
    }

    return (
        <div className={`${className ? className : ''} location-search-bar`} ref={ref}>
            <span className={'mobile-hidden'}>{label}</span>
            <input
                type={'text'}
                placeholder={'Vyberte lokalitu'}
                onClick={onClick}
                onChange={onChange}
                value={inputValue}
            />
            <div className={'loading-indicator' + (loadingSuggestLocation ? ' show' : '')} key={'loader-bigger'}/>
            <div className={'dropdown' + (showDropdown ? ' show' : '')}>
                <div className={'use-my-location'} onClick={loadLocation}>
                    Použít moji polohu
                    <div className={'indicator' + (loadingLocation ? ' show' : '')}/>
                </div>
                <ul className={'suggest-list'}>
                    {(favoriteLocations && locations.length === 0 && !inputValue) &&
                        favoriteLocations.map((favoriteLocation: Location) => {
                            return <li key={favoriteLocation.id} className={'favorite'}
                                       onClick={() => select({
                                           id: favoriteLocation.id,
                                           name: favoriteLocation.name
                                       })}>{favoriteLocation.name}</li>;
                        })}
                    {locations &&
                        locations.map((location: Location) => {
                            return <li
                                className={favoriteLocations.find(favoriteLocation => favoriteLocation.id === location.id) ? 'favorite' : ''}
                                onClick={() => select(location)}>{location.name}</li>;
                        })}
                </ul>
            </div>
        </div>
    );
};

export default LocationSearchBar;
